import styled from "styled-components";
import {
  Rem, Colors, BP, ShadowBlack, 
} from "../../commons/Theme";
import { StyledButton } from "../globalstyle";
import { StyledIcon } from "../Icon/style";

export const StyledBusinessSolutions = styled.div`
  margin-top: ${Rem(-150)};
  @media (${BP.tablet}) {
    margin-top: ${Rem(-560)};
  }
`;

export const StyledBusinessSolutionsLoginLink = styled.div`
  text-align: center;
  margin-top: ${Rem(40)};

  a {
    display: inline-block;
    font-weight: 900;
    font-size: ${Rem(25)};
    text-decoration: none;
    color: ${Colors.yellow};
    padding: ${Rem(10)};
  }
`;

export const StyledBusinessSolutionsCards = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (${BP.ipad}) {
    flex-direction: row;
  }
`;

export const StyledBusinessSolutionsCard = styled.div`
  display: flex;
  flex-direction: column;
  ${ShadowBlack};
  border: ${Rem(4)} solid ${Colors.yellow};
  box-sizing: border-box;
  border-radius: ${Rem(6)};
  padding: ${Rem(15)};
  background-color: ${Colors.white};

  flex-basis: 0;
  flex-grow: 1;

  &:first-child {
    margin-bottom: ${Rem(20)};
  }

  @media (${BP.ipad}) {
    padding: ${Rem(40)};

    &:first-child {
      margin-right: ${Rem(20)};
      margin-bottom: ${Rem(0)};
    }
  }
`;

export const StyledBusinessSolutionsTitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${Rem(30)};

  flex-direction: column-reverse;
  @media (${BP.tablet}) {
    flex-direction: row;
  }

  img {
    height: ${Rem(120)};
  }
`;

export const StyledBusinessSolutionsTitle = styled.h2`
  font-weight: 900;
  font-size: ${Rem(30)};
  text-align: center;
  color: ${Colors.yellow};

  @media (${BP.ipad}) {
    font-size: ${Rem(40)};
  }
`;

export const StyledBusinessSolutionsSubtitle = styled.h3`
  font-weight: 900;
  font-size: ${Rem(20)};
  text-align: center;
  line-height: ${Rem(30)};
  opacity: 0.5;
  margin-bottom: ${Rem(40)};
  flex-grow: 1;

  @media (${BP.ipad}) {
    font-size: ${Rem(25)};
    line-height: ${Rem(40)};
  }
`;

export const StyledBusinessSolutionsFeature = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${Rem(22)};

  ${StyledIcon} {
    width: ${Rem(28)};
    margin-right: ${Rem(18)};
    svg {
      width: ${Rem(28)};
    }
  }
`;

export const StyledBusinessSolutionsFeatureName = styled.h3`
  font-weight: bold;
  font-size: ${Rem(16)};
  line-height: ${Rem(22)};
  
  opacity: ${(props) => props.disabled ? 0.5 : 1};
`;

export const StyledBusinessSolutionsButton = styled(StyledButton)`
  align-self: center;
  width: fit-content;
  margin-top: ${Rem(40)};
  padding: 0;

  a {
    padding: ${Rem(10)} ${Rem(40)};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: inherit;
    z-index: 1;
    position: relative;
  }
`;
