import React, { useContext } from "react";
import { Link } from "gatsby";
import { DataContext } from "../../contexts/DataContext";
import Icon, { icons } from "../Icon/Icon";
import { Colors } from "../../commons/Theme";
import { StyledGridRow } from "../../commons/Grid";

import { 
  StyledBusinessSolutions,
  StyledBusinessSolutionsLoginLink,
  StyledBusinessSolutionsCards,
  StyledBusinessSolutionsCard,
  StyledBusinessSolutionsTitleRow,
  StyledBusinessSolutionsTitle,
  StyledBusinessSolutionsSubtitle,
  StyledBusinessSolutionsFeature,
  StyledBusinessSolutionsFeatureName,
  StyledBusinessSolutionsButton,
} from "./style";

const BusinessSolutions = ({ solutions, loginText }) => {
  const { i18nOasiLink } = useContext(DataContext);
  const oasiLoginUrl = i18nOasiLink("/me");

  return (
    <StyledBusinessSolutions>
      <StyledGridRow noMargin>
        <StyledBusinessSolutionsCards>
          {solutions.map((item, index) => (
            <BusinessSolutionCard key={index} card={item} />
          ))}
        </StyledBusinessSolutionsCards>
        <StyledBusinessSolutionsLoginLink>
          <a href={oasiLoginUrl}>{loginText}</a>
        </StyledBusinessSolutionsLoginLink>
      </StyledGridRow>
    </StyledBusinessSolutions>
  );
};
const BusinessSolutionCard = ({ card }) => {
  const { i18nOasiLink } = useContext(DataContext);
  const { i18nLink } = useContext(DataContext);
  const oasiUrl = i18nOasiLink("/owner/preview/business");
  const link = card.destination === "OASI_PREVIEW" ? oasiUrl : card.destination;

  return (
    <StyledBusinessSolutionsCard>
      <StyledBusinessSolutionsTitleRow>
        <StyledBusinessSolutionsTitle dangerouslySetInnerHTML={{  __html: card.title }}/>
        <img src={card.image} alt='icon'/>
      </StyledBusinessSolutionsTitleRow>

      <StyledBusinessSolutionsSubtitle dangerouslySetInnerHTML={{  __html: card.subtitle }}/>

      {card.features.map((item, index) => (
        <BusinessSolutionFeature key={index} feature={item} />
      ))}

      <StyledBusinessSolutionsButton>
        {link.startsWith("http") === 0 ? (
          <a href={link}  dangerouslySetInnerHTML={{ __html: card.button }}/>
        ) : (
          <Link to={i18nLink(link)}  dangerouslySetInnerHTML={{ __html: card.button }}/>
        )}
      </StyledBusinessSolutionsButton>
    </StyledBusinessSolutionsCard>
  );
};
const BusinessSolutionFeature = ({ feature }) => {
  return (
    <StyledBusinessSolutionsFeature>
      <Icon icon={icons.check} color={feature.excluded ? Colors.transparent : Colors.yellow} />
      <StyledBusinessSolutionsFeatureName 
        dangerouslySetInnerHTML={{  __html: feature.name }}
        disabled={feature.excluded}/>      
    </StyledBusinessSolutionsFeature>
  );
};

export default BusinessSolutions;
